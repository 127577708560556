import { ref } from "vue";
import RegistrationProcessDictionaryProxy from '../../../../proxies/registrationProcessDictionaryProxy';

export default function useDocViewConstructionsRefs() {
    const docViewConstructions = ref([]);

    async function fetchDocViewConstructionsRefs() {
        docViewConstructions.value = await RegistrationProcessDictionaryProxy.getDocViewConstructions();
    }

    fetchDocViewConstructionsRefs();

    return {
        docViewConstructions
    }
}